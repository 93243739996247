import { css } from "@emotion/react";
import { Card, Logo, Section } from "../components";
import { StyleShape2 } from "../components/Shapes";
import Theme from "../components/Theme";

const IMAGE_SIZE = 320;

const styles = {
  base: css`
    display: flex;
    flex-direction: column;

    position: relative;
    ::before {
      ${StyleShape2};
      top: 10%;
      left: clamp(-450px, calc(15% - 650px), -300px);
    }
  `,
  head: css`
    ${Theme.mq.sm_} {
      max-width: 55%;
      margin: auto;
      text-align: center;
    }
  `,
  layout: css`
    margin-top: 100px;
    display: grid;
    align-items: center;

    ${Theme.mq.xs} {
      grid-template-rows: 220px 150px ${IMAGE_SIZE * 0.85}px 150px 300px;
    }

    ${Theme.mq.sm} {
      grid-template-columns: 1fr minmax(40px, 1fr) ${IMAGE_SIZE * 0.85}px minmax(40px, 1fr) 1fr;
    }

    ${Theme.mq.md_} {
      grid-template-columns: 1fr minmax(40px, 1fr) minmax(${IMAGE_SIZE * 0.85}px, ${IMAGE_SIZE}px) minmax(40px, 1fr) 1fr;
    }
  `,
  card: css`
    ${Theme.fonts.bold};
    ${Theme.fontSizes.p};
    color: ${Theme.palette.text};
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    ${Theme.mq._sm} {
      padding: 18px 24px;
      min-height: 90px;
    }

    ${Theme.mq.md_} {
      padding: 24px 30px;
    }
  `,
  l: css`
    ${Theme.mq.xs} {
      position: relative;
      height: 220px;

      > div {
        position: absolute;
        display: flex;

        & > div {
          max-width: 200px;
        }

        & > svg {
          position: absolute;
          display: block;
          bottom: -3.5px;
        }
      }

      > div:nth-of-type(1) {
        width: 50%;
        top: 0;
        left: 0;
        justify-content: flex-start;

        & > svg {
          left: calc(20% - 4px);
        }
      }

      > div:nth-of-type(2) {
        max-width: 200px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;

        & > svg {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      > div:nth-of-type(3) {
        width: 50%;
        top: 0;
        right: 0;
        justify-content: flex-end;

        & > svg {
          right: calc(20% - 4px);
        }
      }
    }

    ${Theme.mq.sm_} {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 24px;
      height: 100%;

      > div {
        position: relative;
        display: flex;

        & > svg {
          position: absolute;
          display: block;
          right: -3.5px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
    }
  `,
  lsh: css`
    display: block;
    height: calc(0.67 * (100% + 24px));
    width: 100%;
    fill: none;
    overflow: visible;

    path {
      stroke-width: 0.5;
    }

    ${Theme.mq.xs} {
      display: none;
    }
  `,
  lsv: css`
    display: block;
    height: 285px;
    width: 80%;
    margin: 0 auto 125px auto;
    fill: none;
    overflow: visible;

    path {
      stroke-width: 0.5;
    }

    ${Theme.mq.sm_} {
      display: none;
    }
  `,
  c: css`
    position: relative;

    > img {
      width: ${IMAGE_SIZE * 0.85}px;
      height: ${IMAGE_SIZE * 0.85}px;

      ${Theme.mq.sm_} {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  `,
  logo: css`
    ${Theme.mq.xs} {
      transform: scale(0.85);
    }
  `,
  rsh: css`
    display: block;
    height: 66.67%;
    width: 100%;
    fill: none;
    overflow: visible;

    path {
      stroke-width: 0.5;
    }

    ${Theme.mq.xs} {
      display: none;
    }
  `,
  rsv: css`
    display: block;
    height: 300px;
    width: 60%;
    margin: 150px auto 0 auto;
    fill: none;
    overflow: visible;

    path {
      stroke-width: 0.5;
    }

    ${Theme.mq.sm_} {
      display: none;
    }
  `,
  r: css`
    > div {
      display: flex;
      & > div {
        padding: 12px;
        > h6 {
          ${Theme.fonts.bold};
          ${Theme.fontSizes.p};
          color: ${Theme.palette.text};
        }
        > div {
          ${Theme.fonts.regular};
          ${Theme.fontSizes.small};
          color: ${Theme.palette.muted};
        }
      }
    }

    ${Theme.mq.xs} {
      position: relative;
      min-height: 300px;

      > div {
        position: absolute;
        display: flex;

        & > svg {
          position: absolute;
          display: block;
          top: -8px;
        }
      }

      > div:nth-of-type(1) {
        width: 50%;
        top: 0;
        left: 0;
        justify-content: flex-start;

        & > div {
          width: 80%;
          text-align: left;
        }

        & > svg {
          left: calc(40% - 4px);
        }
      }

      > div:nth-of-type(2) {
        width: 40%;
        top: 150px;
        left: 0;
        right: 0;
        margin: 0 auto;

        & > div {
          text-align: center;
        }

        & > svg {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      > div:nth-of-type(3) {
        width: 50%;
        top: 0;
        right: 0;
        justify-content: flex-end;

        & > div {
          width: 80%;
          text-align: right;
        }

        & > svg {
          right: calc(40% - 4px);
        }
      }
    }

    ${Theme.mq.sm_} {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      align-items: center;
      align-content: space-between;
      height: 100%;

      > div {
        position: relative;
        & > svg {
          position: absolute;
          display: block;
          left: -8px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
    }
  `,
};

export interface SectionHomeDiagramProps {
  heading: string;
  left: Array<string>;
  right: Array<{
    heading: string;
    content: string;
  }>;
}

const COLORS = [Theme.palette.blue, Theme.palette.neon, Theme.palette.primary];

export const SectionHomeDiagram = ({ heading, left, right }: SectionHomeDiagramProps) => {
  return (
    <div css={styles.base}>
      <Section marginTop marginBottom>
        <h4 css={[Theme.typography.h4, styles.head]} dangerouslySetInnerHTML={{ __html: heading }} />
        <div css={styles.layout}>
          <div css={styles.l}>
            {left.map((entry, index) => (
              <div key={`i${index}`}>
                <Card sx={styles.card}>{entry}</Card>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  css={{
                    filter: `drop-shadow(0px 0px 4px ${COLORS[index]})`,
                  }}>
                  <circle cx="4" cy="4" r="4" fill={COLORS[index]} />
                </svg>
              </div>
            ))}
          </div>
          <svg viewBox="0 0 100 100" preserveAspectRatio="none" css={styles.lsh}>
            <path d="M100 50C50 50 75 0 15 0L0 0" stroke={Theme.palette.blue} />
            <path d="M100 50L0 50" stroke={Theme.palette.neon} />
            <path d="M100 50C50 50 75 100 15 100L0 100" stroke={Theme.palette.primary} />
          </svg>
          <svg viewBox="0 0 100 100" preserveAspectRatio="none" css={styles.lsv}>
            <path d="M50 100C50 50 0 75 0 15L0 0" stroke={Theme.palette.blue} />
            <path d="M50 100L50 45" stroke={Theme.palette.neon} />
            <path d="M50 100C50 50 100 75 100 15L100 0" stroke={Theme.palette.primary} />
          </svg>
          <div css={styles.c}>
            <img src="/assets/platform-circles.png" width={IMAGE_SIZE} height={IMAGE_SIZE} />
            <Logo sx={styles.logo} />
          </div>
          <svg viewBox="0 0 100 100" preserveAspectRatio="none" css={styles.rsh}>
            <path d="M0 50C50 50 25 0 85 0L100 0" stroke={Theme.palette.primary} />
            <path d="M0 50L100 50" stroke={Theme.palette.neon} />
            <path d="M0 50C50 50 25 100 85 100L100 100" stroke={Theme.palette.blue} />
          </svg>
          <svg viewBox="0 0 100 200" preserveAspectRatio="none" css={styles.rsv}>
            <path d="M50 0C50 50 0 25 0 85L0 100" stroke={Theme.palette.primary} />
            <path d="M50 0L50 200" stroke={Theme.palette.neon} />
            <path d="M50 0C50 50 100 25 100 85L100 100" stroke={Theme.palette.blue} />
          </svg>
          <div css={styles.r}>
            {right.map(({ heading, content }, index) => (
              <div key={`i${index}`}>
                <div>
                  <h6>{heading}</h6>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </div>
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  css={{
                    filter: `drop-shadow(0px 0px 4px ${COLORS[2 - index]})`,
                  }}>
                  <circle cx="4" cy="4" r="4" fill={COLORS[2 - index]} />
                </svg>
              </div>
            ))}
          </div>
        </div>
      </Section>
    </div>
  );
};
